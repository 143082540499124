import { ButtonProps } from '~/components/interactive/Button';
import { useIsZodFormValid } from '~/components/zod/ZodForm';
import LoadingButton from '~/components/interactive/LoadingButton';

export default function ZodSubmitButton({
  showError,
  disabled,
  ...props
}: Omit<ButtonProps, 'type'> & {
  showError?: boolean;
}) {
  const valid = useIsZodFormValid();

  const button = <LoadingButton type="submit" {...props} disabled={valid !== true || disabled} />;

  if (!showError) {
    return button;
  }

  return (
    <div className="inline-flex items-center space-x-2">
      {valid !== true && <div className="text-xs text-red-600 font-semibold">{valid}</div>}
      {button}
    </div>
  );
}
